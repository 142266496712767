.footercontainer {
  background-color: #f9f9f9;
  margin-top: 16px;
  padding: 17px 0;
}

.footerWraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footerNavLink,
.socialMediaLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footerNavLink a {
  display: flex;
  margin-right: 26px;
  /* font-family: 'Untitled Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 190%;
  color: #000000;
  text-decoration: none;
}

.socialMediaLink a {
  display: flex;
  margin-left: 15px;
  text-decoration: none;
}

@media only screen and (max-width: 525px) {
  .socialMediaLink {
    width: 100%;
  }

  .footerNavLink {
    margin-bottom: 30px;
  }

  .socialMediaLink a {
    margin-left: 0px;
    margin-right: 15px;
  }
}

/* @media only screen and (max-width: 400px) {
  .footerNavLink {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .footerNavLink a {
    margin-bottom: 10px;
  }
} */
@media only screen and (max-width: 600px) {
  .footerNavLink {
    margin-bottom: 20px;
    width: 100%;
    flex-wrap: wrap;
  }
  .footerNavLink a {
    margin-right: 13px;
    margin-bottom: 10px;
  }
}
