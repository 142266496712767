.hideMobile {
    display: flex;
    margin-right: 25px;
}

.showMobile {
    display: none;
}

.menu_list {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #000000;
}

@media only screen and (max-width: 600px) {
    .hideMobile {
        display: none;
    }
    .showMobile {
        display: flex;
        background: #000000;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }
}