@font-face {
  font-family: "untitled_sanslight_italic";
  src: url("./fonts/untitled_sans_light_italic-webfont.woff2") format("woff2"),
    url("./fonts/untitled_sans_light_italic-webfont.woff") format("woff"),
    url("./fonts/untitled_sans_light_italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "untitled_sansregular_regular";
  src: url("./fonts/untitled_sans_regular.woff2-webfont.woff2") format("woff2"),
    url("./fonts/untitled_sans_regular.woff2-webfont.woff") format("woff"),
    url("./fonts/untitled_sans_regular.woff2-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "untitled_sansregular_italic";
  src: url("./fonts/untitled_sans_regular_italic-webfont.woff2") format("woff2"),
    url("./fonts/untitled_sans_regular_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
